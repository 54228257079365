import RootLayout from '@/components/layout/Layout'
import styles from '@/styles/LegalInformation.module.scss'
import {Element} from 'react-scroll';
import {useEffect, useState} from "react";
import {ILegalInformationPage} from "@/api/models/legal_information.models";
import {getLegalInformationPage} from "@/api/services/legal_information.service";

export default function LegalInformationPage() {
    const [pageData, setPageData] = useState<ILegalInformationPage | undefined>({});

    useEffect(() => {
        window.scrollTo({
            top: 0
        })

        const setInitialData = async () => {
            const pageDataResponse = await getLegalInformationPage();

            setPageData(prevState => pageDataResponse);
        };

        setInitialData()
            .catch(error => console.error(error));
    }, [])

    return (
        <RootLayout pageTitle={pageData?.page?.title ?? ''} theme={'light'}>
            <div className={styles.pageContainerWrapper}>
                {
                    pageData?.page?.chapters?.length
                        ?
                        <div className={styles.pageContainer}>
                            {
                                pageData.page?.chapters.map((chapter) => (
                                    <Element className={styles.contentBlock} name={chapter.title ?? ''} key={chapter.id}>
                                        <div>
                                            <h2 className={styles.contentBlock__title}>{chapter.title}</h2>
                                            <div
                                                className={styles.contentBlock__textSection}
                                                dangerouslySetInnerHTML={{__html: chapter.text ?? ''}}>
                                            </div>
                                        </div>
                                    </Element>
                                ))
                            }
                        </div>
                        :
                        ''
                }
                <a href={'https://zgalex.com/'} className={styles.copyright}>Сделано в ZG.Agency</a>
            </div>
        </RootLayout>
    )
}
