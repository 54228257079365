import { easeInOutQuad } from "./SeqPlyaer.easings";
import type { TransitionFn, TransitionFrame } from "./SeqPlyaer.types";

export const defaultTransition: TransitionFn = (
  ctx: CanvasRenderingContext2D,
  progress: number,
  frame: TransitionFrame,
) => {
  progress = easeInOutQuad(progress);
  const invertedProgress = 1 - progress;
  ctx.drawImage(
    frame.freezed.orig,
    0,
    0,
    frame.freezed.orig.width * invertedProgress,
    frame.freezed.orig.height,
    frame.freezed.dx,
    frame.freezed.dy,
    frame.freezed.width * invertedProgress,
    frame.freezed.height,
  );
  ctx.drawImage(
    frame.current.orig,
    frame.current.orig.width * invertedProgress,
    0,
    frame.current.orig.width * progress,
    frame.current.orig.height,
    frame.current.width * invertedProgress,
    frame.current.dy,
    frame.current.width * progress,
    frame.current.height,
  );
};