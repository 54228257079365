import classNames from "classnames";
import styles from "./TruckScene.module.scss";
import { Title } from "@/atoms/title/Title";

export const IntroTitle = () => {
  return (
    <div className={classNames(styles.title)}>
      <Title>Большой ассортимент</Title>
      <br />
      <Title isItalic>грузовых шин</Title>
    </div>
  );
};

export const FirstSlideTitle = () => {
  return (
    <div className={classNames(styles.title)}>
      <Title>Передняя <Title isItalic>ось</Title></Title>
    </div>
  );
}

export const SecondSlideTitle = () => {
  return (
    <div className={classNames(styles.title)}>
      <Title>Ведущая <Title isItalic>ось</Title></Title>
    </div>
  );
}

export const ThirdSlideTitle = () => {
  return (
    <div className={classNames(styles.title)}>
      <Title>Прицепная <Title isItalic>ось</Title></Title>
    </div>
  );
}

export const FourthSlideTitle = () => {
  return (
    <div className={classNames(styles.title)}>
      <Title>Универсальная <Title isItalic>ось</Title></Title>
    </div>
  );
}

export const FifthSlideTitle = () => {
  return (
    <div className={classNames(styles.title)}>
      <Title>Зимние <Title isItalic>шины</Title></Title>
    </div>
  );
}