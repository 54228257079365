import { useState } from "react";

export const useIsMobile = () => {
  const userAgent =
    typeof window.navigator === 'undefined' ? '' : navigator.userAgent;
  const [isMobile] = useState(Boolean(
    userAgent.match(
      /Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i
    )
  ));

  return { isMobile, isDesktop: !isMobile };
};
