import sendRequest from "@/api/sendRequest";
import {FeedbackForm, FormResponse} from "@/api/models/form.models";

export function sendFeedbackForm (data: FeedbackForm): Promise<FormResponse> {
    const url = '/api/feedback/send-partner-request';
    const method = 'POST';

    return new Promise((resolve, reject) => {
        sendRequest(url, method, data)
            .then((response) => {
                resolve(response as FormResponse ?? {});
            })
            .catch((e) => {
                reject(e);
            });
    });
}