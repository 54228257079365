import classNames from "classnames";
import styles from "./TruckScene.module.scss";
import { useMemo } from "react";
import { mainEasing } from "@/constants/easings";
import { motion } from "framer-motion";

export const Pagination = ({
  visible,
  pagesCount,
  currentPage,
  delayIn = 0,
  delayOut = 0,
}: {
  visible: boolean,
  pagesCount: number,
  currentPage: number,
  delayIn: number;
  delayOut: number;
}) => {
  const variants = useMemo(() => {
    return {
      itemInner: {
        visible: (idx: number) => ({
          y: 0,
          // opacity: 1,
          transition: {
            delay: idx * 0.1 + delayIn,
            duration: 0.5,
            ease: mainEasing,
            from: '100%',
            // from: 0,
          },
        }),
        hidden: (idx: number) => ({
          y: '-102%',
          // opacity: 0,
          transition: {
            delay: idx * 0.05 + delayOut,
            duration: 0.3,
            ease: mainEasing,
          },
        }),
      }
    };
  }, [delayIn, delayOut]);

  return (
    <div className={classNames(styles.paginationContainer)}>
      {
        new Array(pagesCount)
          .fill(null)
          .map((_, idx) => (
            <div
              key={idx}
              className={classNames(styles.paginationItem, {
                [styles.paginationItem_active]: idx === currentPage
              })}
            >
              <motion.div
                className={classNames(styles.paginationItemInner)}
                variants={variants.itemInner}
                initial='hidden'
                animate={visible ? 'visible' : 'hidden'}
                custom={idx}
              >
                0{idx + 1}.
              </motion.div>
            </div>
          ))
      }
    </div>
  );
}