import styles from './BurgerMenuItem.module.scss';
import classNames from 'classnames';
import { motion } from 'framer-motion';
import { useCallback, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';

interface BurgeMenuItemProps {
  title?: string;
  url?: string;
}

export const BurgerMenuItem = ({
    title = '',
    url = '',
}: BurgeMenuItemProps) => {
  const [isItemHovered, setIsItemHovered] = useState(false);
  const [animationName, setAnimationName] = useState('initial');

  const variants = useMemo(() => {
    const lineTransition = {
      duration: 0.3,
      ease: [0.79, 0.09, 0.21, 0.98],
    };
    return {
      line: {
        visible: {
          x: 0,
          transition: lineTransition,
        },
        hidden: {
          x: '101%',
          transition: lineTransition,
        },
        initial: {
          x: '-101%',
          transition: {
            duration: 0,
          },
        },
      }
    }
  }, []);

  const onItemAnimationComplete = useCallback((definition: string) => {
    switch (definition) {
      case 'initial':
        if (isItemHovered) {
          setAnimationName('visible');
        }
        break;
      case 'hidden':
        setAnimationName('initial');
        break;
    }
  }, [isItemHovered]);

  function onItemMouseEnter() {
    setIsItemHovered(true);
    setAnimationName('visible');
  }

  function onItemMouseLeave() {
    setIsItemHovered(false);
    setAnimationName('hidden');
  }

  return (
    <Link
      className={classNames(styles.menuItem, 'menuItem')}
      to={url}
      onMouseEnter={onItemMouseEnter}
      onMouseLeave={onItemMouseLeave}
    >
      <span className={classNames(styles.menuItem__innerWrapper)}>
        {title}
        <motion.span
          className={classNames(styles.menuItem__line, 'menuItem__line')}
          initial='initial'
          variants={variants.line}
          animate={animationName}
          onAnimationComplete={onItemAnimationComplete}
        ></motion.span>
      </span>
    </Link>
  );
}