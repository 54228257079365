import classNames from "classnames";
import styles from "./TruckScene.module.scss";

export const FirstSlideFirstDesc = () => {
  return (
    <div className={classNames(styles.description)}>
      Курсовая устойчивость при движении прямо и на поворотах.
    </div>
  );
};

export const FirstSlideSecondDesc = () => {
  return (
    <div className={classNames(styles.description)}>
      Шины передней оси должны соответствовать особым требованиям из-за прямого соединения с управляемой осью.
    </div>
  );
}

export const FirstSlideThirdDesc = () => {
  return (
    <div className={classNames(styles.description)}>
      Они должны обеспечивать курсовую устойчивость при движении прямо и курсовую устойчивость на поворотах.
    </div>
  );
}

export const SecondSlideFirstDesc = () => {
  return (
    <div className={classNames(styles.description)}>
      Качественные тягово-сцепные характеристики, повышенная устойчивость, экономичность.
    </div>
  );
}

export const SecondSlideSecondDesc = () => {
  return (
    <div className={classNames(styles.description)}>
      Ведущие шины автомобиля обычно несут наибольшую нагрузку.
    </div>
  );
}

export const SecondSlideThirdDesc = () => {
  return (
    <div className={classNames(styles.description)}>
      Они должны выводить на дорогу тягу для ускорения, а также передавать тормозные силы и замедление через двигатель на землю при отпускании акселератора.
    </div>
  );
}

export const ThirdSlideFirstDesc = () => {
  return (
    <div className={classNames(styles.description)}>
      Экономичность, распределение веса.
    </div>
  );
}

export const ThirdSlideSecondDesc = () => {
  return (
    <div className={classNames(styles.description)}>
      Их задача состоит в том, чтобы распределить вес автомобиля и передать большее тормозное усилие на прицеп, а также придать автомобилю боковую устойчивость в случае боковых сил.
    </div>
  );
}

export const FourthSlideFirstDesc = () => {
  return (
    <div className={classNames(styles.description)}>
      Особенно низкое сопротивление качению, стойкость к порезам.
    </div>
  );
}

export const FourthSlideSecondDesc = () => {
  return (
    <div className={classNames(styles.description)}>
      Особенно низкое сопротивление качению и индивидуальное специальное оснащение, такое как особенно стойкая к порезам резиновая смесь делают такие шины идеальным выбором для вашего автомобиля.
    </div>
  );
}

export const FifthSlideFirstDesc = () => {
  return (
    <div className={classNames(styles.description)}>
      Качественные тягово-сцепные характеристики на заснеженных и обледенелых дорогах, курсовая устойчивость на поворотах в зимних условиях.
    </div>
  );
}

export const FifthSlideSecondDesc = () => {
  return (
    <div className={classNames(styles.description)}>
      Зимние шины становятся все более и более важными.
    </div>
  );
}

export const FifthSlideThirdDesc = () => {
  return (
    <div className={classNames(styles.description)}>
      Благодаря более глубокому профилю и сложной структуре ламелей вы и ваш автомобиль идеально подготовлены к холодному сезону с зимними шинами.
    </div>
  );
}