// axios will be configured here for api requests
import axios, {AxiosInstance} from "axios";

const instance: AxiosInstance = axios.create({
    // The prefix REACT_APP_ is required, for more information see https://github.com/dilanx/craco/issues/116
    baseURL: process.env.NODE_ENV === 'development' ? 'http://shininvest-engine.devel.zgalex.com' : '',
});

instance.defaults.headers.post['Content-Type'] = 'application/json';

export default instance;
