export interface ICity {
    code: string;
    id: string;
    title: string;
}

export type TCities = ICity[];

export enum ESeoPages {
    Main = 'main',
    Tires = 'tires',
}

export interface IPageSeo {
    title?: string;
    keywords?: string;
    description?: string;
}
