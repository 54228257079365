import styles from '@/atoms/title/Title.module.scss'
import classNames from 'classnames'
import { PropsWithChildren } from 'react'

export const Title = (
  {
    children,
    isItalic,
    isDark,
    isWhite
  }:
    PropsWithChildren<{
      isItalic?: boolean,
      isDark?: boolean,
      isWhite?: boolean
    }>) => {
  return <span className={classNames(styles.title, { [styles.isItalic]: isItalic }, { [styles.isDark]: isDark }, { [styles.isWhite]: isWhite })}>{children}</span>
}
