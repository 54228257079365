import { Title } from '@/atoms/title/Title';
import styles from './TruckSceneMobile.module.scss';

export const TruckSceneMobile = () => {
  return <div className={styles.container}>
    <div className={styles.previewSlide}>
      <div className={styles.previewSlide__title}><Title>Большой ассортимент<Title isItalic> <br />грузовых шин</Title></Title></div>
      <video
        className={styles.backgroundVideo}
        loop={true}
        muted={true}
        autoPlay={true}
        playsInline
        src={process.env.PUBLIC_URL + '/videos/truckTiresMobile/video6.mov'}
      />
    </div>
  </div>
} 