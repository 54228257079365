import { useEffect, useMemo, useState } from 'react';
import styles from './CurvedText.module.scss';
import { AnimatePresence, motion } from 'framer-motion';

enum Brands {
  none = '',
  voltair_prom = 'voltair_prom',
  nortek = 'nortek',
}

type BrandNames = Exclude<Brands, Brands.none>;

export const CurvedText = () => {
  const imagePaths: Record<Brands, string> = useMemo(() => {
    return {
      [Brands.none]: '',
      [Brands.voltair_prom]: '/images/curvedTextBanners/banner_voltyre.jpg',
      [Brands.nortek]: '/images/curvedTextBanners/banner_nortec.jpg',
    };
  }, []);

  const brandOrder: BrandNames[] = useMemo(() => {
    return [
      Brands.nortek,
      Brands.voltair_prom,
    ];
  }, []);

  const brandToNum: Record<BrandNames, number> = useMemo(() => {
    return brandOrder
      .reduce((prev, cur, index) => ({...prev, [cur]: index}), {} as Record<BrandNames, number>);
  }, [brandOrder]);

  const variants = useMemo(() => {
    const bannerTransition = {
      duration: 0.5,
      ease: [0.79, 0.09, 0.21, 0.98],
    };
    return {
      banner: {
        enter: {
          opacity: 1,
          x: 0,
          transition: bannerTransition,
        },
        initial: {
          opacity: 0,
          x: -50,
          transition: bannerTransition,
        },
        exit: {
          opacity: 0,
          x: 50,
          transition: bannerTransition,
        },
      }
    };
  }, []);

  const [isBrandActive, setIsBrandActive] = useState(false);
  const [curImageNum, setCurImageNum] = useState(-1);
  const [bannerPath, setBannerPath] = useState(imagePaths[Brands.none]);

  useEffect(() => {
    const isActive = curImageNum !== -1;
    setIsBrandActive(isActive);
    setBannerPath(isActive ? imagePaths[brandOrder[curImageNum]] : imagePaths[Brands.none]);
  }, [curImageNum, brandOrder, imagePaths]);

  useEffect(() => {
    const onClick = (e: any) => {
      const svgEl = e.target.closest('#curved-text');
      if (!svgEl) {
        setCurImageNum(-1);
      }
    };
    document.body.addEventListener('click', onClick, false);
    return () => document.body.removeEventListener('click', onClick, false);
  }, []);

  function onTextMouseEnter(index: number) {
    if (index < 0 || index >= brandOrder.length) {
      throw new Error('brand index is out of bounds');
    }
    setCurImageNum(index);
  }

  function onTextMouseLeave() {
    setCurImageNum(-1);
  }

  function onCircleClick() {
    setCurImageNum((prev) => (prev + 1) % brandOrder.length);
  }

  return (
    <div
      id='curved-text'
      // style={{ cursor: 'pointer' }}
      onClick={onCircleClick}
    >
      <svg
        className={styles.icon}
        width="254"
        height="254"
        viewBox="0 0 254 254"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        onMouseLeave={onTextMouseLeave}
      >
        <g
          onMouseEnter={() => onTextMouseEnter(brandToNum[Brands.voltair_prom])}
        >
          <path d="M237.285 127.969L253.148 128.888L252.811 134.697C252.746 135.823 252.507 136.744 252.091 137.462C251.683 138.178 251.152 138.703 250.501 139.032C249.855 139.363 249.139 139.506 248.354 139.46C247.694 139.422 247.143 139.268 246.702 138.998C246.267 138.73 245.925 138.384 245.674 137.96C245.429 137.542 245.259 137.086 245.163 136.593L245.008 136.584C244.952 137.11 244.751 137.614 244.407 138.096C244.069 138.584 243.602 138.978 243.011 139.274C242.418 139.572 241.712 139.697 240.89 139.649C240.079 139.602 239.363 139.369 238.739 138.949C238.121 138.535 237.646 137.922 237.315 137.11C236.989 136.298 236.861 135.279 236.932 134.05L237.285 127.969ZM239.199 130.481L238.999 133.936C238.932 135.082 239.107 135.916 239.522 136.438C239.937 136.959 240.478 137.239 241.144 137.278C241.645 137.307 242.112 137.207 242.545 136.978C242.978 136.749 243.332 136.408 243.607 135.952C243.882 135.501 244.038 134.956 244.075 134.316L244.28 130.776L239.199 130.481ZM246.147 130.884L245.961 134.091C245.93 134.628 246.005 135.117 246.187 135.557C246.368 136.003 246.638 136.363 246.994 136.638C247.357 136.917 247.794 137.072 248.304 137.102C248.96 137.14 249.523 136.942 249.994 136.509C250.465 136.074 250.728 135.383 250.783 134.432L250.973 131.164L246.147 130.884Z" fill="white" />
          <path d="M241.588 156.536C239.922 156.212 238.55 155.628 237.47 154.786C236.396 153.945 235.64 152.931 235.201 151.744C234.768 150.563 234.681 149.301 234.943 147.955C235.206 146.604 235.761 145.461 236.607 144.524C237.458 143.594 238.541 142.941 239.858 142.565C241.175 142.189 242.663 142.163 244.323 142.486C245.988 142.81 247.359 143.392 248.433 144.235C249.512 145.077 250.27 146.088 250.703 147.269C251.142 148.456 251.229 149.724 250.966 151.075C250.704 152.42 250.148 153.558 249.298 154.489C248.452 155.425 247.37 156.082 246.053 156.458C244.741 156.835 243.253 156.861 241.588 156.536ZM242.041 154.206C243.31 154.453 244.419 154.456 245.368 154.214C246.32 153.977 247.089 153.547 247.674 152.924C248.263 152.306 248.645 151.55 248.819 150.657C248.994 149.758 248.924 148.912 248.609 148.118C248.3 147.325 247.749 146.639 246.955 146.057C246.167 145.482 245.137 145.071 243.868 144.824C242.599 144.577 241.488 144.571 240.536 144.807C239.588 145.049 238.819 145.479 238.23 146.097C237.645 146.716 237.266 147.474 237.091 148.373C236.917 149.267 236.984 150.11 237.294 150.903C237.607 151.701 238.159 152.389 238.948 152.964C239.741 153.545 240.772 153.959 242.041 154.206Z" fill="white" />
          <path d="M233.508 156.227L235.47 156.913L235.331 157.358C235.137 157.959 235.176 158.498 235.448 158.977C235.716 159.46 236.259 159.932 237.073 160.391C237.885 160.857 239.008 161.37 240.439 161.931L247.395 164.651L244.078 174.135L229.079 168.889L229.86 166.655L242.941 171.23L244.73 166.111L239.085 163.873C237.501 163.248 236.208 162.615 235.205 161.974C234.206 161.335 233.537 160.607 233.197 159.792C232.856 158.983 232.884 158.009 233.282 156.871L233.508 156.227Z" fill="white" />
          <path d="M240.542 177.248L242.384 178.177L236.848 189.158L235.004 188.228L237.237 183.801L224.892 177.575L225.968 175.441L238.313 181.667L240.542 177.248Z" fill="white" />
          <path d="M220.807 184.953L222.236 182.848L232.176 196.499L230.621 198.791L214.261 194.605L215.689 192.498L228.973 196.08L229.042 195.977L220.807 184.953ZM225.717 188.799L221.083 195.632L219.414 194.499L224.047 187.667L225.717 188.799Z" fill="white" />
          <path d="M225.176 206.314L223.591 208.082L214.903 200.292L214.805 200.403L218.096 214.211L216.615 215.863L204.784 205.257L206.384 203.471L215.061 211.25L215.16 211.141L211.869 197.354L213.344 195.707L225.176 206.314ZM222.882 214.917L221.608 216.338C220.957 215.755 220.634 215.049 220.641 214.221C220.647 213.4 221 212.598 221.701 211.816C222.399 211.039 223.155 210.602 223.971 210.506C224.79 210.414 225.525 210.659 226.176 211.243L224.907 212.659C224.618 212.399 224.286 212.254 223.911 212.223C223.539 212.196 223.169 212.388 222.799 212.801C222.437 213.205 222.288 213.593 222.351 213.962C222.412 214.336 222.589 214.655 222.882 214.917Z" fill="white" />
          <path d="M202.982 206.926L213.372 218.948L209.087 222.651C208.151 223.459 207.23 223.959 206.321 224.15C205.412 224.34 204.55 224.271 203.734 223.945C202.921 223.621 202.197 223.092 201.561 222.356C200.922 221.617 200.501 220.818 200.3 219.96C200.098 219.109 200.157 218.244 200.478 217.366C200.798 216.494 201.423 215.656 202.356 214.85L205.302 212.304L206.631 213.842L203.849 216.247C203.258 216.758 202.866 217.274 202.674 217.794C202.486 218.32 202.459 218.835 202.595 219.34C202.731 219.845 202.995 220.324 203.387 220.778C203.779 221.233 204.214 221.561 204.69 221.764C205.167 221.968 205.677 222.008 206.222 221.887C206.762 221.769 207.333 221.449 207.936 220.929L210.214 218.96L201.168 208.493L202.982 206.926Z" fill="white" />
          <path d="M191.17 225.94L190.03 224.286L195.831 220.289L196.972 221.944L191.17 225.94Z" fill="white" />
          <path d="M180.693 241.233L173.225 227.207L175.334 226.084L181.84 238.301L188.442 234.786L181.936 222.569L184.045 221.445L191.513 235.47L180.693 241.233Z" fill="white" />
          <path d="M170.542 228.348L176.124 243.224L170.821 245.214C169.663 245.648 168.626 245.797 167.707 245.661C166.788 245.525 166.004 245.163 165.351 244.573C164.701 243.988 164.205 243.241 163.863 242.331C163.52 241.415 163.403 240.52 163.51 239.645C163.616 238.777 163.971 237.986 164.576 237.273C165.178 236.566 166.056 235.997 167.208 235.565L170.855 234.196L171.569 236.099L168.126 237.391C167.395 237.666 166.849 238.015 166.488 238.437C166.129 238.864 165.925 239.338 165.878 239.859C165.83 240.38 165.912 240.92 166.123 241.483C166.333 242.045 166.627 242.503 167.003 242.859C167.38 243.214 167.845 243.43 168.398 243.504C168.946 243.58 169.592 243.478 170.338 243.199L173.156 242.141L168.297 229.19L170.542 228.348Z" fill="white" />
          <path d="M146.172 243.774C145.797 242.119 145.777 240.628 146.113 239.301C146.45 237.978 147.072 236.877 147.982 235.997C148.887 235.124 150.008 234.536 151.344 234.233C152.686 233.929 153.956 233.975 155.155 234.372C156.349 234.775 157.384 235.503 158.259 236.556C159.134 237.609 159.759 238.961 160.132 240.611C160.507 242.266 160.526 243.754 160.189 245.077C159.854 246.404 159.233 247.505 158.329 248.378C157.419 249.257 156.294 249.85 154.952 250.154C153.615 250.456 152.349 250.406 151.155 250.004C149.957 249.607 148.92 248.881 148.045 247.828C147.171 246.78 146.547 245.428 146.172 243.774ZM148.487 243.249C148.773 244.511 149.218 245.526 149.821 246.296C150.422 247.072 151.125 247.602 151.933 247.886C152.735 248.177 153.58 248.221 154.469 248.02C155.361 247.817 156.107 247.412 156.707 246.805C157.307 246.201 157.714 245.42 157.926 244.46C158.134 243.506 158.096 242.398 157.81 241.137C157.524 239.876 157.081 238.858 156.481 238.081C155.876 237.311 155.173 236.781 154.37 236.491C153.568 236.206 152.721 236.165 151.828 236.367C150.94 236.568 150.196 236.97 149.596 237.573C148.991 238.182 148.584 238.963 148.377 239.917C148.165 240.878 148.201 241.988 148.487 243.249Z" fill="white" />
          <path d="M143.246 252.25L140.343 252.44L134.486 240.447L134.3 240.459L130.061 253.116L127.158 253.307L126.117 237.451L128.393 237.301L129.147 248.775L129.294 248.765L133.218 237.008L135.107 236.884L140.536 248.035L140.682 248.025L139.929 236.544L142.205 236.395L143.246 252.25Z" fill="white" />
        </g>

        <path d="M97.1025 241.185L97.5167 239.219L108.206 241.47L107.792 243.436L97.1025 241.185Z" fill="white" />
        
        <g
          onMouseEnter={() => onTextMouseEnter(brandToNum[Brands.nortek])}
        >
          <path d="M83.5088 228.434L76.4063 242.648L74.2617 241.576L77.3483 235.399L70.2621 231.859L67.1756 238.035L65.0241 236.96L72.1266 222.746L74.2781 223.821L71.1811 230.019L78.2674 233.56L81.3642 227.362L83.5088 228.434Z" fill="white" />
          <path d="M54.1175 220.209C55.08 218.811 56.1632 217.786 57.3669 217.133C58.5677 216.484 59.7995 216.197 61.0624 216.269C62.3181 216.342 63.5103 216.768 64.639 217.546C65.772 218.327 66.5987 219.292 67.1191 220.442C67.6323 221.593 67.7983 222.848 67.6168 224.205C67.4353 225.562 66.8648 226.937 65.9051 228.33C64.9426 229.727 63.861 230.75 62.6601 231.399C61.4565 232.052 60.2268 232.342 58.971 232.268C57.7082 232.195 56.5102 231.769 55.3773 230.988C54.2485 230.211 53.4276 229.246 52.9143 228.095C52.3939 226.945 52.2244 225.691 52.4059 224.333C52.5844 222.981 53.1549 221.606 54.1175 220.209ZM56.0725 221.556C55.3389 222.62 54.8936 223.636 54.7369 224.602C54.5728 225.57 54.6598 226.445 54.9978 227.231C55.3288 228.018 55.869 228.67 56.6186 229.186C57.3725 229.706 58.176 229.98 59.0293 230.009C59.8796 230.042 60.7296 229.811 61.5794 229.317C62.422 228.823 63.2101 228.044 63.9437 226.979C64.6774 225.914 65.1262 224.898 65.2903 223.93C65.4471 222.964 65.36 222.088 65.0292 221.301C64.6954 220.518 64.1515 219.867 63.3977 219.347C62.648 218.831 61.8481 218.556 60.9977 218.524C60.1403 218.492 59.2902 218.722 58.4477 219.216C57.5979 219.711 56.8062 220.491 56.0725 221.556Z" fill="white" />
          <path d="M56.8273 212.074L46.1346 223.828L41.9451 220.016C41.0307 219.185 40.4245 218.329 40.1266 217.45C39.8288 216.57 39.7933 215.705 40.0206 214.857C40.2442 214.011 40.6832 213.228 41.3376 212.509C41.9954 211.786 42.7382 211.274 43.5661 210.971C44.3867 210.668 45.2523 210.624 46.1631 210.837C47.0666 211.051 47.9737 211.572 48.8843 212.4L51.7654 215.021L50.3974 216.525L47.6771 214.05C47.0993 213.524 46.5402 213.197 45.9995 213.069C45.4554 212.944 44.9408 212.98 44.4557 213.175C43.9706 213.37 43.5262 213.689 43.1225 214.133C42.7186 214.578 42.4444 215.048 42.2994 215.545C42.1545 216.043 42.1751 216.555 42.361 217.08C42.5431 217.603 42.9288 218.132 43.518 218.668L45.7448 220.693L55.0538 210.461L56.8273 212.074Z" fill="white" />
          <path d="M36.916 212.401L35.3495 213.745L27.3424 204.412L28.9087 203.068L32.1368 206.831L42.6303 197.828L44.1862 199.642L33.6929 208.644L36.916 212.401Z" fill="white" />
          <path d="M38.2552 192.44L25.0724 201.311L19.5106 193.046L21.2229 191.894L25.4462 198.17L29.4628 195.467L25.5297 189.623L27.2355 188.475L31.1686 194.319L35.2045 191.603L30.9293 185.25L32.6414 184.098L38.2552 192.44Z" fill="white" />
          <path d="M27.006 173.397L23.0503 181.37L23.8119 182.88L30.1714 179.673L31.2509 181.814L17.0634 188.969L15.9839 186.828L21.9761 183.806L21.6233 183.107L12.9268 180.767L11.6097 178.155L20.8236 180.625L25.6889 170.785L27.006 173.397Z" fill="white" />
        </g>

        <path d="M10.6746 146.921L12.6377 146.492L14.9705 157.164L13.0073 157.593L10.6746 146.921Z" fill="white" />
        
        <g
          onMouseEnter={() => onTextMouseEnter(brandToNum[Brands.voltair_prom])}
        >
          <path d="M16.7322 129.081L0.850098 128.591L1.02974 122.775C1.06455 121.648 1.27949 120.72 1.67456 119.992C2.06446 119.264 2.58045 118.726 3.22252 118.379C3.85942 118.031 4.57083 117.869 5.35664 117.893C6.01837 117.914 6.57295 118.053 7.02039 118.309C7.46272 118.566 7.81457 118.904 8.07593 119.32C8.33229 119.732 8.51508 120.183 8.62414 120.673L8.77917 120.677C8.82132 120.151 9.00785 119.641 9.33867 119.15C9.66457 118.653 10.1195 118.248 10.7036 117.934C11.2877 117.621 11.9907 117.477 12.8127 117.503C13.6244 117.527 14.3475 117.741 14.9819 118.144C15.6113 118.542 16.1025 119.142 16.4555 119.944C16.8033 120.746 16.9582 121.763 16.9202 122.993L16.7322 129.081ZM14.7511 126.621L14.858 123.163C14.8934 122.015 14.6965 121.186 14.2672 120.676C13.838 120.166 13.2898 119.901 12.6229 119.88C12.1214 119.864 11.6574 119.977 11.2308 120.218C10.8042 120.458 10.4596 120.809 10.1969 121.272C9.93431 121.73 9.79321 122.279 9.77336 122.92L9.66394 126.464L14.7511 126.621ZM7.795 126.406L7.89414 123.196C7.9107 122.658 7.82231 122.172 7.62877 121.736C7.43532 121.295 7.15648 120.943 6.79207 120.678C6.42266 120.408 5.98196 120.265 5.47016 120.249C4.81362 120.228 4.25585 120.441 3.79705 120.888C3.33822 121.335 3.09412 122.034 3.06473 122.985L2.96365 126.258L7.795 126.406Z" fill="white" />
          <path d="M11.6522 100.65C13.3256 100.929 14.7131 101.476 15.8148 102.289C16.9115 103.101 17.6947 104.096 18.1645 105.27C18.6301 106.439 18.75 107.699 18.5241 109.051C18.2974 110.408 17.7736 111.566 16.9527 112.524C16.1275 113.478 15.0618 114.159 13.7555 114.571C12.4492 114.981 10.962 115.048 9.29366 114.769C7.62035 114.49 6.23535 113.944 5.13866 113.131C4.03693 112.318 3.25328 111.327 2.78772 110.158C2.31792 108.984 2.19636 107.718 2.42305 106.361C2.64888 105.009 3.17439 103.857 3.99957 102.904C4.82049 101.944 5.88414 101.26 7.19039 100.848C8.49155 100.437 9.97878 100.37 11.6522 100.65ZM11.261 102.992C9.98561 102.779 8.87717 102.806 7.93569 103.073C6.99003 103.335 6.23308 103.785 5.66484 104.425C5.09232 105.058 4.7311 105.823 4.58107 106.722C4.43023 107.625 4.52263 108.469 4.85818 109.254C5.18873 110.037 5.75815 110.709 6.56663 111.269C7.37083 111.823 8.41071 112.207 9.68611 112.42C10.9616 112.633 12.0721 112.608 13.0178 112.347C13.9592 112.079 14.7162 111.63 15.2887 110.996C15.8561 110.361 16.2152 109.593 16.366 108.69C16.5161 107.792 16.4258 106.951 16.0953 106.167C15.7606 105.377 15.1911 104.705 14.3869 104.151C13.5784 103.591 12.5365 103.205 11.261 102.992Z" fill="white" />
          <path d="M19.7281 100.701L17.7479 100.067L17.8752 99.6182C18.053 99.0128 18 98.4748 17.7164 98.0032C17.4344 97.5271 16.8803 97.0701 16.054 96.6313C15.2293 96.188 14.0941 95.7055 12.6485 95.1821L5.62341 92.6468L8.68891 83.0781L23.8209 87.9257L23.0989 90.1797L9.90276 85.9518L8.24812 91.1165L13.9503 93.2039C15.5504 93.7874 16.8603 94.3864 17.88 95.0009C18.8949 95.6136 19.5828 96.3227 19.944 97.1284C20.3066 97.9286 20.3042 98.9036 19.9364 100.051L19.7281 100.701Z" fill="white" />
          <path d="M12.0852 79.9467L10.219 79.0655L15.4694 67.9453L17.3357 68.8265L15.2189 73.3096L27.7213 79.2127L26.701 81.3736L14.1987 75.4705L12.0852 79.9467Z" fill="white" />
          <path d="M31.6944 71.5385L30.3204 73.6805L20.033 60.2885L21.5285 57.957L37.9904 61.723L36.6164 63.8651L23.2457 60.6255L23.1787 60.73L31.6944 71.5385ZM26.6872 67.8198L31.1443 60.8712L32.8422 61.9604L28.3852 68.9089L26.6872 67.8198Z" fill="white" />
          <path d="M26.3983 50.7682L27.9309 48.9551L36.8424 56.4884L36.9375 56.3758L33.2453 42.6685L34.6779 40.9739L46.8126 51.2321L45.2649 53.0629L36.3653 45.5396L36.2702 45.6522L39.9605 59.3377L38.533 61.0263L26.3983 50.7682ZM28.4412 42.1022L29.6734 40.6445C30.341 41.2089 30.6837 41.9051 30.7018 42.733C30.7191 43.5537 30.3889 44.365 29.711 45.1669C29.0365 45.9648 28.2932 46.4235 27.481 46.5429C26.665 46.659 25.9232 46.4349 25.2556 45.8705L26.4828 44.4189C26.779 44.6693 27.1152 44.8045 27.4911 44.8244C27.8633 44.8409 28.228 44.6378 28.5853 44.2152C28.9359 43.8004 29.0738 43.409 28.9989 43.0409C28.9273 42.6689 28.7414 42.356 28.4412 42.1022Z" fill="white" />
          <path d="M48.6741 49.3431L37.9816 37.5893L42.1712 33.778C43.0856 32.9461 43.9946 32.4234 44.8981 32.2098C45.8016 31.9962 46.6653 32.0425 47.4893 32.3487C48.3099 32.6512 49.0474 33.162 49.7017 33.8813C50.3595 34.6045 50.8001 35.3924 51.0234 36.2451C51.2471 37.0905 51.2098 37.9566 50.9116 38.8431C50.6137 39.7225 50.0095 40.5763 49.0989 41.4048L46.2178 44.0257L44.8499 42.522L47.5703 40.0472C48.148 39.5217 48.5262 38.9959 48.7049 38.4697C48.88 37.9397 48.8934 37.4241 48.745 36.9228C48.5966 36.4214 48.3205 35.9489 47.9168 35.5051C47.5131 35.0612 47.0704 34.7438 46.5888 34.5525C46.1072 34.3613 45.5959 34.3335 45.0549 34.469C44.5178 34.6011 43.9545 34.9351 43.3653 35.4711L41.1386 37.4969L50.4476 47.7297L48.6741 49.3431Z" fill="white" />
          <path d="M59.6265 30.2305L60.8146 31.8512L55.1326 36.0161L53.9446 34.3954L59.6265 30.2305Z" fill="white" />
          <path d="M70.0549 14.3789L77.8833 28.2062L75.8039 29.3836L68.9845 17.3386L62.4759 21.0235L69.2952 33.0685L67.2158 34.2458L59.3873 20.4184L70.0549 14.3789Z" fill="white" />
          <path d="M80.2197 27.0711L74.2493 12.3459L79.498 10.2177C80.6437 9.75319 81.6773 9.57695 82.5989 9.68883C83.5206 9.80071 84.3151 10.1428 84.9824 10.715C85.6479 11.2824 86.1629 12.0167 86.5288 12.9179C86.8957 13.8238 87.0368 14.7155 86.9521 15.5928C86.8693 16.4634 86.5352 17.2632 85.9487 17.9921C85.3652 18.7142 84.5031 19.3065 83.3623 19.7691L79.7528 21.2325L78.9891 19.3488L82.3971 17.967C83.1209 17.6735 83.6576 17.3108 84.0072 16.8788C84.3547 16.442 84.5455 15.9628 84.5796 15.4411C84.6136 14.9194 84.518 14.3806 84.2925 13.8245C84.0671 13.2685 83.7615 12.8175 83.3756 12.4716C82.9898 12.1257 82.5197 11.9228 81.9652 11.863C81.4154 11.8013 80.7715 11.92 80.0333 12.2193L77.2436 13.3504L82.4414 26.1704L80.2197 27.0711Z" fill="white" />
          <path d="M104.14 10.9246C104.559 12.5686 104.618 14.0588 104.319 15.3949C104.017 16.726 103.424 17.8434 102.538 18.7468C101.656 19.6439 100.552 20.2617 99.2236 20.6001C97.8899 20.9397 96.6191 20.9272 95.411 20.5626C94.2058 20.1916 93.1525 19.4913 92.2494 18.4616C91.3464 17.4319 90.6864 16.0976 90.2685 14.4585C89.8498 12.8145 89.7915 11.3269 90.0928 9.99582C90.3923 8.65962 90.9831 7.54299 91.8653 6.64584C92.751 5.74242 93.8598 5.12083 95.1935 4.78118C96.5217 4.44281 97.788 4.4591 98.9923 4.83007C100.201 5.19475 101.257 5.8919 102.159 6.92159C103.062 7.94627 103.722 9.28056 104.14 10.9246ZM101.84 11.5107C101.52 10.2576 101.049 9.25425 100.424 8.50058C99.8035 7.74062 99.0861 7.22956 98.2723 6.9672C97.4621 6.69864 96.6154 6.6767 95.7333 6.90138C94.8466 7.12742 94.1111 7.55227 93.5285 8.17586C92.944 8.79444 92.558 9.58644 92.3723 10.552C92.1893 11.5113 92.2576 12.6174 92.5771 13.8705C92.8958 15.1236 93.3664 16.1301 93.9864 16.89C94.6109 17.6437 95.3282 18.1549 96.1384 18.4235C96.9477 18.6871 97.7961 18.7059 98.6828 18.4799C99.5649 18.2551 100.299 17.8334 100.883 17.2149C101.471 16.59 101.856 15.798 102.039 14.8387C102.225 13.8732 102.159 12.7638 101.84 11.5107Z" fill="white" />
          <path d="M107.455 2.19381L110.353 1.93607L116.486 13.7911L116.671 13.7746L120.616 1.02336L123.514 0.765625L124.922 16.5928L122.649 16.7949L121.631 5.34184L121.484 5.35486L117.833 17.1999L115.947 17.3676L110.262 6.34504L110.116 6.35815L111.135 17.8189L108.862 18.021L107.455 2.19381Z" fill="white" />
        </g>

        <path d="M153.549 12.0076L153.188 13.9843L142.441 12.0197L142.803 10.043L153.549 12.0076Z" fill="white" />
        
        <g
          onMouseEnter={() => onTextMouseEnter(brandToNum[Brands.nortek])}
        >
          <path d="M167.722 24.4093L174.453 10.0156L176.625 11.0311L173.7 17.2862L180.875 20.6417L183.8 14.3866L185.979 15.4055L179.248 29.7991L177.07 28.7804L180.005 22.5042L172.829 19.1487L169.894 25.4248L167.722 24.4093Z" fill="white" />
          <path d="M196.96 31.6142C196.034 33.0355 194.977 34.0879 193.791 34.7716C192.607 35.451 191.383 35.7706 190.119 35.7306C188.862 35.6891 187.659 35.2941 186.51 34.5456C185.358 33.7943 184.506 32.8505 183.957 31.7142C183.414 30.5763 183.217 29.3267 183.363 27.9651C183.51 26.6036 184.045 25.2143 184.968 23.7974C185.895 22.3761 186.95 21.3258 188.133 20.6465C189.32 19.9628 190.542 19.6417 191.799 19.6833C193.064 19.7232 194.272 20.1189 195.424 20.8703C196.573 21.6187 197.418 22.5619 197.96 23.6997C198.51 24.836 198.712 26.0849 198.566 27.4465C198.422 28.8036 197.887 30.1929 196.96 31.6142ZM194.971 30.3177C195.677 29.2345 196.096 28.2079 196.228 27.2382C196.367 26.267 196.258 25.3929 195.9 24.6162C195.549 23.838 194.993 23.2003 194.23 22.7031C193.462 22.2032 192.652 21.9497 191.799 21.9428C190.948 21.9315 190.104 22.1842 189.268 22.7007C188.437 23.2157 187.67 24.0148 186.964 25.0981C186.258 26.1814 185.835 27.2086 185.695 28.1799C185.563 29.1497 185.674 30.0237 186.024 30.8019C186.378 31.5758 186.938 32.2128 187.705 32.7127C188.467 33.2098 189.275 33.464 190.125 33.4752C190.984 33.4851 191.827 33.2325 192.657 32.7175C193.493 32.2009 194.265 31.401 194.971 30.3177Z" fill="white" />
          <path d="M194.68 39.897L205.069 27.875L209.355 31.5785C210.29 32.3868 210.918 33.2267 211.238 34.0982C211.558 34.9697 211.616 35.8328 211.41 36.6875C211.208 37.5383 210.789 38.3316 210.154 39.0673C209.514 39.8069 208.784 40.3386 207.964 40.6623C207.152 40.9856 206.287 41.0522 205.372 40.8622C204.463 40.6716 203.544 40.1739 202.611 39.3689L199.665 36.8221L200.994 35.2842L203.777 37.6889C204.367 38.1996 204.935 38.5122 205.479 38.6266C206.026 38.7371 206.54 38.6887 207.019 38.4814C207.499 38.2741 207.935 37.9435 208.327 37.4895C208.72 37.0355 208.982 36.5581 209.114 36.0571C209.247 35.556 209.213 35.0451 209.014 34.5242C208.818 34.0067 208.419 33.4875 207.817 32.9667L205.539 30.9982L196.494 41.4647L194.68 39.897Z" fill="white" />
          <path d="M214.772 39.2113L216.304 37.8281L224.545 46.9546L223.014 48.3379L219.691 44.6584L209.43 53.925L207.829 52.1515L218.09 42.885L214.772 39.2113Z" fill="white" />
          <path d="M213.801 58.9713L226.753 49.7656L232.524 57.8855L230.842 59.0812L226.46 52.9153L222.513 55.7202L226.595 61.4623L224.919 62.6535L220.838 56.9114L216.873 59.7297L221.309 65.9714L219.628 67.167L213.801 58.9713Z" fill="white" />
          <path d="M225.711 77.9338L229.458 69.8607L228.658 68.3707L222.384 71.7416L221.249 69.6297L235.246 62.1094L236.382 64.2213L230.469 67.3976L230.84 68.0879L239.594 70.2016L240.979 72.7782L231.704 70.548L227.095 80.5105L225.711 77.9338Z" fill="white" />
        </g>

        <path d="M242.749 103.946L240.798 104.427L238.181 93.8205L240.132 93.3398L242.749 103.946Z" fill="white" />
      </svg>
      <AnimatePresence>
        {isBrandActive &&
          <motion.img
            key={curImageNum}
            className={styles.banner}
            alt=''
            src={`${process.env.PUBLIC_URL}${bannerPath}`}
            width={603}
            height={157}
            variants={variants.banner}
            initial='initial'
            animate='enter'
            exit='exit'
          />
        }
      </AnimatePresence>
    </div>
  );
}