import { createSlice } from '@reduxjs/toolkit';
import { RootState } from './RootState';

export const pullCursorSlice = createSlice({
  name: 'pullCursor',
  initialState: {
    isVisible: false,
  },
  reducers: {
    showPullCursor: (state) => {
      state.isVisible = true;
    },
    hidePullCursor: (state) => {
        state.isVisible = false;
    }
  },
});

export const { showPullCursor, hidePullCursor } = pullCursorSlice.actions;

export const selectIsCursorVisible = (state: RootState) => state.pullCursor.isVisible;

export default pullCursorSlice.reducer;
