import sendRequest from "@/api/sendRequest";
import {ESeoPages, ICity, IPageSeo, TCities} from "@/api/models/seo.models";

export function getCitiesList (): Promise<TCities> {
    const url = '/api/city/list';
    const method = "GET";

    return new Promise((resolve, reject) => {
        sendRequest(url, method)
            .then((response) => {
                resolve(response as TCities ?? []);
            })
            .catch((e) => {
                reject(e);
            });
    });
}

export function getDefaultCity (): Promise<ICity> {
    const url = '/api/city/get-default';
    const method = "GET";

    return new Promise((resolve, reject) => {
        sendRequest(url, method)
            .then((response) => {
                resolve(response as ICity ?? {});
            })
            .catch((e) => {
                reject(e);
            });
    });
}

export function getPageSeoService (city: string, page: ESeoPages, category: string = ''): Promise<IPageSeo> {
    let url = '';
    const method = "GET";

    switch (page) {
        case ESeoPages.Main:
            url = `/api/main/seo?city=${city}`
            break;
        case ESeoPages.Tires:
            url = `/api/tyres/seo/${category}?city=${city}`
            break;
        default:
            url = `/api/main/seo?city=${city}`
    }



    return new Promise((resolve, reject) => {
        sendRequest(url, method)
            .then((response) => {
                resolve(response as IPageSeo ?? {});
            })
            .catch((e) => {
                reject(e);
            });
    });
}
