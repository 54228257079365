import classNames from "classnames";
import styles from "./Loader.module.scss";

export const Loader = ({
  progress,
}: {
  progress?: number;
}) => {
  return (
    <div className={classNames(styles.loader)}>
      <div className={classNames(styles.loaderParts)}></div>
      <div className={classNames(styles.loaderParts)}></div>
      <div className={classNames(styles.loaderParts)}></div>
      <div className={classNames(styles.loaderParts)}></div>
      <div className={classNames(styles.loaderParts)}></div>
      {progress !== undefined &&
        <div className={classNames(styles.loaderPercentage)}>{Math.round(progress * 100)}%</div>
      }
    </div>
  );
}