import { SVGMotionProps, motion } from 'framer-motion';
import { useMemo } from 'react';
import styles from './ScrollDown.module.scss';
import classNames from 'classnames';

interface ScrollDownProps extends SVGMotionProps<SVGGElement> {
  visible: boolean;
}

export const ScrollDown = ({
  visible,
  ...props
}: ScrollDownProps) => {
  const variants = useMemo(() => ({
    icon: {
      visible: {
        opacity: 1,
      },
      hidden: {
        opacity: 0,
      },
    },
    scroll: {
      animate: {
        y: 40,
        transition: {
          y: {
            repeat: Infinity,
            ease: 'easeOut',
            duration: 0.5,
            // repeatDelay: 1.5,
            // delay: 1.5,
            from: 0,
          },
        },
      },
      freeze: {
        y: 0,
      },
    }
  }), []);
  return (
    <motion.svg
      width="40px"
      height="100%"
      viewBox="0 0 247 390"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      variants={variants.icon}
      initial='hidden'
      animate={visible ? 'visible' : 'hidden'}
      {...props}
    >
      <path
        className={classNames(styles.scrollDown__scroll)}
        d="M123.359,79.775l0,72.843"
        fill='none'
        stroke='#fff'
        strokeWidth='5'
      />
      <path
        d="M236.717,123.359c0,-62.565 -50.794,-113.359 -113.358,-113.359c-62.565,0 -113.359,50.794 -113.359,113.359l0,143.237c0,62.565 50.794,113.359 113.359,113.359c62.564,0 113.358,-50.794 113.358,-113.359l0,-143.237Z"
        fill='none'
        stroke='#fff'
        strokeWidth='5'
      />
    </motion.svg>
  );
}