import styles from './PullCursor.module.scss';
import { useRef } from 'react';
import { motion } from 'framer-motion';
import { useFollowPointer } from '@/hooks/useFollowPointer';
import { useIsMobile } from '@/hooks/useIsMobile';
import classNames from 'classnames';

export const PullCursor = () => {
  const ref = useRef(null!);
  const { coord, isVisible } = useFollowPointer(ref);
  const { isDesktop } = useIsMobile();

  return (
    <motion.div
      ref={ref}
      className={classNames(styles.pullCursor, {
        [styles.pullCursor_visible]: isDesktop && isVisible
      })}
      animate={{ x: coord.x, y: coord.y }}
      transition={{
        ease: 'circOut',
        duration: 0,
        scale: {
          type: 'spring',
          stiffness: 500,
          damping: 15
        }
      }}
    >
      <div className={classNames(styles.pullCursor__content)}>
        Потяни
      </div>
    </motion.div>
  );
};
