import { Suspense, useEffect, useState } from "react";
import { ITiresCategory, ITiresContacts } from "@/api/models/tires.models";
import { getTiresCategory, getTiresContacts } from "@/api/services/tires.service";
import TiresCategory from "@/components/TiresCategory/TiresCategory";
import { useParams } from "react-router-dom";
import { useIsMobile } from "@/hooks/useIsMobile";
import {getPageSeoService} from "@/api/services/seo.service";
import {ESeoPages, IPageSeo} from "@/api/models/seo.models";
import {Helmet} from "react-helmet";

export default function TiresCategoryPage({city}: {city: string}) {
    const { url } = useParams();
    const [pageData, setPageData] = useState<ITiresCategory | undefined>({});
    const [pageContacts, setPageContacts] = useState<ITiresContacts | undefined>({});
    const [pageSeo, setPageSeo] = useState<IPageSeo>({});
    const [screenThemes, setScreenThemes] = useState<string[]>([]);
    const [currentTheme, setCurrentTheme] = useState<string>('light');
    const [currentSlide, setCurrentSlide] = useState<number>(0)
    const { isMobile } = useIsMobile();

    useEffect(() => {
        const getThemes = (pageBlocks: ITiresCategory | undefined, contactsBlock: ITiresContacts | undefined): string[] => {
            const themes: string[] = [];
            themes.push(pageBlocks?.category?.theme ?? 'light');

            if (pageBlocks?.truckScene) {
                // с бэка нет данных по этому блоку, но на мобилке слайдов всегда 6
                if (isMobile) {
                    for (let i = 0; i < 6; i++) {
                        themes.push('dark');
                    }
                } else {
                    themes.push('dark');
                }
            }

            if (pageBlocks?.category?.info_blocks?.length) {
                pageBlocks?.category.info_blocks.forEach((infoBlock) => {
                    themes.push(infoBlock?.theme ?? 'light');
                });
            }

            if (pageBlocks?.category?.sliders?.length) {
                themes.push('light');
            }

            if (pageBlocks?.category?.partners?.length) {
                themes.push('light');
            }

            if (contactsBlock) {
                themes.push('light');
            }

            return themes;
        };

        const setInitialData = async () => {
            const pageDataResponse = await getTiresCategory(url);
            const pageContactsResponse = await getTiresContacts(url);

            setPageData(pageDataResponse);
            setPageContacts(pageContactsResponse);

            const themes = getThemes(pageDataResponse, pageContactsResponse);

            setScreenThemes(themes);
            setCurrentTheme(themes[0] ?? 'light')
        };

        setInitialData()
            .catch(error => console.error(error));
    }, [isMobile, url])

    useEffect(() => {
        const getPageSeo = async () => {
            const pageSeo = await getPageSeoService(city, ESeoPages.Tires, url);

            if (pageSeo) {
                setPageSeo(pageSeo);
            }
        }

        if (city) {
            getPageSeo()
                .catch(e => console.log(e))
        }
    }, [city, url]);

    return (
        <Suspense>
            <Helmet>
                {pageSeo.title && (
                    <title>{pageSeo.title}</title>
                )}
                {pageSeo.description && (
                    <meta name="description" content={pageSeo.description} />
                )}
                {pageSeo.keywords && (
                    <meta name="keywords" content={pageSeo.keywords} />
                )}
            </Helmet>
            <TiresCategory
                pageData={pageData}
                pageContacts={pageContacts}
                currentTheme={currentTheme}
                onChangeScreen={(index) => {
                    setCurrentTheme(screenThemes[index])
                    setCurrentSlide(index);
                }}
                onSetCustomTheme={(theme) => {
                    if (theme) {
                        setCurrentTheme(theme)
                    } else {
                        setCurrentTheme(screenThemes[currentSlide])
                    }
                }}
            />
        </Suspense>
    )
}
