import {Header} from "../header/Header";

export default function RootLayout({
   children,
   pageTitle,
    theme
}: {
    children: React.ReactNode,
    pageTitle: string,
    theme: string
}) {
    return (
        <div className={`theme--${theme}`}>
            <Header pageTitle={pageTitle}/>
            <main>
                {children}
            </main>
        </div>
    );
}