import {ITiresCategory} from "@/api/models/tires.models";
import sendRequest from "@/api/sendRequest";

export function getMainPage (): Promise<ITiresCategory> {
    const url = '/api/main';
    const method = "GET";

    return new Promise((resolve, reject) => {
        sendRequest(url, method)
            .then((response) => {
                resolve(response as ITiresCategory ?? {});
            })
            .catch((e) => {
                reject(e);
            });
    });
}
