import sendRequest from "@/api/sendRequest";
import {ITiresCategories, ITiresCategory, ITiresContacts} from "@/api/models/tires.models";

export function getTiresCategories (): Promise<ITiresCategories> {
    const url = '/api/tyres/categories';
    const method = "GET";

    return new Promise((resolve, reject) => {
        sendRequest(url, method)
            .then((response) => {
                resolve(response as ITiresCategories ?? []);
            })
            .catch((e) => {
                reject(e);
            });
    });
}

export function getTiresCategory (categoryUrl: string | undefined): Promise<ITiresCategory> {
    const url = `/api/tyres/category/${categoryUrl}`;
    const method = "GET";

    return new Promise((resolve, reject) => {
        sendRequest(url, method)
            .then((response) => {
                resolve(response as ITiresCategory ?? {});
            })
            .catch((e) => {
                reject(e);
            });
    });
}

export function getTiresContacts (categoryUrl: string | undefined): Promise<ITiresContacts> {
    const url = `/api/tyres/contacts/${categoryUrl}`;
    const method = "GET";

    return new Promise((resolve, reject) => {
        sendRequest(url, method)
            .then((response) => {
                resolve(response as ITiresContacts ?? {});
            })
            .catch((e) => {
                reject(e);
            });
    });
}