import classNames from "classnames"
import styles from '@/atoms/subTitle/SubTitle.module.scss'

export const SubTitle = (
  {
    children
  }:
    {
      children: string
    }) => {
  return <h4 className={classNames(styles.text)}>{children}</h4>
}