import {Title} from '@/atoms/title/Title'
import styles from './PartnerForm.module.scss'
import {Input} from '@/atoms/input/Input'
import {useNavigate} from 'react-router-dom'
import {Formik} from 'formik';
import {useMemo, useState} from "react";
import {sendFeedbackForm} from "@/api/services/form.service";
import classNames from "classnames";
import {useDispatch, useSelector} from 'react-redux';
import {closeForm, selectIsOpen} from '@/store/slices/PartnerFormSlice';
import {AnimatePresence, motion} from 'framer-motion';

interface formFields {
    name?: string;
    phone?: string;
    email?: string;
    company?: string;
}

export const PartnerForm = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [formSuccess, setFormSuccess] = useState<boolean>(false);
    const [formPending, setFormPending] = useState<boolean>(false);
    const isFormOpen = useSelector(selectIsOpen);

    const variants = useMemo(() => {
        const formTransition = {
            duration: 0.3,
            ease: [0.79, 0.09, 0.21, 0.98],
        };
        return {
            form: {
                hidden: {
                    y: 50,
                    opacity: 0,
                    transition: formTransition,
                },
                visible: {
                    y: 0,
                    opacity: 1,
                    transition: formTransition,
                },
            },
        };
    }, []);

    const goToLegalInformationPage = (evt: React.MouseEvent) => {
        evt.preventDefault();
        dispatch(closeForm());
        navigate('/legalInformation')
    }

    return (
        <AnimatePresence>
            {isFormOpen &&
                <motion.div
                    className={classNames(styles.showMoreModal)}
                    variants={variants.form}
                    initial='hidden'
                    animate='visible'
                    exit='hidden'
                >
                    <Formik
                        initialValues={{
                            name: '',
                            phone: '',
                            email: '',
                            company: ''
                        }}
                        validate={(values) => {
                            const errors: formFields = {};

                            const minLengthName = 2;
                            const checkValidPhone = /(\+7|8|7)[-\s]?\(?(\d{3})\)?[-\s]?(\d{3})[-\s]?(\d{2})[-\s]?(\d{2})$/.test(values.phone);
                            const checkValidEmail = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email);

                            if (values.name.length < minLengthName) {
                                errors.name = `Имя должно содержать более ${minLengthName} символов`;
                            }

                            if (!checkValidEmail) {
                                errors.email = 'Введите корректный email';
                            }

                            if (!checkValidPhone) {
                                errors.phone = 'Введите корректный номер телефона';
                            }

                            if (!values.company) {
                                errors.company = 'Укажите компанию'
                            }

                            return errors;
                        }}
                        onSubmit={(values) => {
                            setFormPending(true);
                            sendFeedbackForm(values)
                                .then((res) => {
                                    if (res.status === 'ok') {
                                        setFormPending(false);
                                        setFormSuccess(true);
                                    }
                                });
                        }}
                    >
                        {
                            ({
                                 values,
                                 errors,
                                 handleChange,
                                 handleSubmit,
                             }) => (
                                <>
                                    {
                                        formSuccess
                                            ?
                                            <div className={styles.successMessageContainer}>
                                                <div className={styles.successMessageContainer__textContainer}>
                                                    <p className={styles.successMessageContainer__title}>Заявка
                                                        отправлена!</p>
                                                    <button type='button'
                                                            className={styles.successMessageContainer__button}>
                                                        <svg width="20" height="21" viewBox="0 0 20 21" fill="none"
                                                             xmlns="http://www.w3.org/2000/svg">
                                                            <path fillRule="evenodd" clipRule="evenodd"
                                                                  d="M10.3859 10.6833L1.63976 19.2409L1 18.6149L9.74618 10.0574L1.63991 2.12596L2.27967 1.5L10.3859 9.43142L18.4922 1.5L19.132 2.12596L11.0257 10.0574L19.7719 18.6149L19.1321 19.2409L10.3859 10.6833Z"
                                                                  fill="#C4C4C4"/>
                                                        </svg>
                                                    </button>
                                                </div>
                                                <p className={styles.successMessageContainer__description}>Мы свяжемся с
                                                    вами в ближайшее время.</p>
                                            </div>
                                            :
                                            <div className={styles.pageSection}>
                                                <Title>Стать <br/><Title isItalic>партнером</Title></Title>
                                                <form
                                                    onSubmit={handleSubmit}
                                                    className={classNames(styles.container, formPending ? styles.pending : '')}
                                                >
                                                    <div className={styles.inputsWrapper}>
                                                        <Input
                                                            type='text'
                                                            label='ФИО'
                                                            isRequired
                                                            name={'name'}
                                                            value={values.name}
                                                            onChange={handleChange}
                                                        />
                                                        <Input
                                                            type='tel'
                                                            label='Телефон'
                                                            name={'phone'}
                                                            isRequired
                                                            value={values.phone}
                                                            onChange={handleChange}
                                                        />
                                                        <Input
                                                            type='text'
                                                            label='Компания'
                                                            name={'company'}
                                                            isRequired
                                                            value={values.company}
                                                            onChange={handleChange}
                                                        />
                                                        <Input
                                                            type='email'
                                                            label='Email'
                                                            name={'email'}
                                                            isRequired
                                                            value={values.email}
                                                            onChange={handleChange}
                                                        />
                                                    </div>
                                                    <p className={styles.inputSection__title}>
                                                        {Object.values(errors).join(',')}
                                                    </p>
                                                    <button type='submit' className={styles.submitButton}>Отправить
                                                    </button>
                                                    <div className={styles.agreementContainer}>
                                                        Нажимая «Отправить» вы даете
                                                        <a
                                                            href={'/legalInformation'}
                                                            onClick={(e) => goToLegalInformationPage(e)}
                                                        >
                                                            согласие на обработку персональных данных.
                                                        </a>
                                                        <br/>
                                                        <a
                                                            href={'/legalInformation'}
                                                            onClick={(e) => goToLegalInformationPage(e)}
                                                        >
                                                            Политика конфиденциальности.
                                                        </a>
                                                    </div>
                                                </form>
                                                <button
                                                    className={styles.closeButton}
                                                    type='button'
                                                    onClick={() => dispatch(closeForm())}
                                                >
                                                    <svg width="63" height="61" viewBox="0 0 63 61" fill="none"
                                                         xmlns="http://www.w3.org/2000/svg">
                                                        <path fillRule="evenodd" clipRule="evenodd"
                                                              d="M31.1218 31.1213L2.12132 60.1218L0 58.0005L29.0005 29L2.12181 2.12132L4.24313 0L31.1218 26.8787L58.0005 0L60.1218 2.12132L33.2431 29L62.2436 58.0005L60.1223 60.1218L31.1218 31.1213Z"
                                                              fill="#C4C4C4"/>
                                                    </svg>
                                                </button>
                                            </div>
                                    }
                                </>
                            )
                        }
                    </Formik>
                </motion.div>
            }
        </AnimatePresence>
    );
}