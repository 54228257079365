import styles from './RoundItem.module.scss'

export const RoundItem = ({
  src,
  videoSrc
}: {
  src?: string | null,
  videoSrc?: string | null
}) => {
  return <>
    {src &&
      <img
        className={
          styles.image}
        src={process.env.PUBLIC_URL + src}
        width='394'
        height='394'
        alt=''
      >
      </img>
    }
    {videoSrc &&
      <video
        className={
          styles.video}
        loop={true}
        muted={true}
        playsInline
        preload='auto'
        autoPlay={true}
        src={process.env.PUBLIC_URL + videoSrc}
      ></video>
    }
  </>
}