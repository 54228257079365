import classNames from 'classnames'
import styles from './FullScreenSlide.module.scss'
import { useState } from 'react';
import { createPortal } from 'react-dom';
import { ShowMoreSlide } from '../showMoreSlide/ShowMoreSlide';

export const FullScreenSlide = ({
    backgroundVideoSrc,
    title,
    caption,
    desc,
    id,
    backgroundImageSrc,
    onSetCustomTheme,
}: {
    backgroundVideoSrc?: string | null,
    title: TrustedHTML | string,
    desc: TrustedHTML | string,
    caption: TrustedHTML | string,
    id: string,
    backgroundImageSrc?: string | null,
    onSetCustomTheme?: (theme: string | null) => void
}) => {
    const parentComponent = document.getElementById(id) as HTMLElement;

    const [showModal, setShowModal] = useState(false);

    return <div className={styles.pageSection} id={id}>

        <div
            className={classNames(styles.backgroundVideoWrapper)}
        >
            {showModal && createPortal(
                <div className={styles.showMoreModal}>
                    <ShowMoreSlide
                        onClose={() => {
                            if (onSetCustomTheme) {
                                onSetCustomTheme(null)
                            }
                            setShowModal(false)
                        }}
                        caption={caption ?? ''}
                        desc={desc ?? ''}
                    />
                </div>,
                parentComponent
            )}

            {backgroundImageSrc && <img
                className={classNames(styles.backgroundImage)}
                src={process.env.PUBLIC_URL + backgroundImageSrc}
                alt=''
            />}
            {backgroundVideoSrc && <video
                className={classNames(styles.backgroundVideo)}
                loop={true}
                muted={true}
                autoPlay={true}
                playsInline
                src={process.env.PUBLIC_URL + backgroundVideoSrc}
            />}

        </div>
        <div
            className={classNames(styles.textWrapper)}
        >
            <div dangerouslySetInnerHTML={{ __html: title }}></div>
            <button
                type='button'
                className={classNames(styles.moreButton)}
                onClick={() => {
                    if (onSetCustomTheme) {
                        onSetCustomTheme('light')
                    }
                    setShowModal(true)
                }}
            >
                Подробнее
            </button>
        </div>
        {/* <div className={styles.contentBlock}>
      <div className={styles.buttonContainer}>
        <div className={styles.circleImageContainer}>
          <img
            alt=''
            width={117}
            height={117}
            src={process.env.PUBLIC_URL + '/images/disks/image1.png'}
            className={styles.buttonImage}
          />
          <img
            alt=''
            width={263}
            height={263}
            src={process.env.PUBLIC_URL + '/images/disks/circleText.png'}
            className={styles.circleText}
          />

        </div>

        <button type='button' className={styles.button}>
          <svg width="72" height="72" viewBox="0 0 72 72" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="36" cy="36" r="35.5" stroke="#C4C4C4" /><line x1="36.5" y1="16" x2="36.5" y2="55" stroke="#C4C4C4" /><line x1="17" y1="34.5" x2="56" y2="34.5" stroke="#C4C4C4" /></svg>
        </button>
      </div>
    </div> */}

    </div>
}
