import { selectIsCursorVisible } from "@/store/slices/PullCursor";
import { useState, RefObject, useEffect } from "react";
import { useSelector } from "react-redux";

export function useFollowPointer(ref: RefObject<HTMLElement>) {
  const [point, setPoint] = useState({ x: 0, y: 0 });
  const isVisible = useSelector(selectIsCursorVisible);

  useEffect(() => {
    if (!ref.current) return;

    const handlePointerMove = ({ clientX, clientY }: MouseEvent) => {
      // if (!isVisible) {
      //   return;
      // }
      const element = ref.current!;

      const x = clientX - element.offsetLeft - element.offsetWidth / 2;
      const y = clientY - element.offsetTop - element.offsetHeight / 2;
      setPoint({ x, y });
    };

    window.addEventListener('pointermove', handlePointerMove);

    return () => {
      window.removeEventListener('pointermove', handlePointerMove);
    };
  }, [isVisible, ref]);

  return { coord: point, isVisible };
}
