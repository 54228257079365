import { RoundItem } from '@/atoms/roundItem/RoundItem'
import styles from './PartnersSlide.module.scss'
import { ITiresCategoryPartner } from "@/api/models/tires.models";
import axiosInstance from "@/api/axiosInstance";
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/scss';

export const PartnersSlide = ({ partners }: { partners: ITiresCategoryPartner[] }) => {
  const baseUrl = axiosInstance.defaults.baseURL;
  const partnersNames = partners.filter(partner => {
    return partner.title?.length
  }).map(partner => partner.title)

  return (
    <div className={styles.pageSection}>
      {
        partnersNames?.length
          ?
          <p className={styles.title}>Мы работаем с: {partnersNames.join(', ')}</p>
          :
          ''
      }

      <div className={styles.imagesContainer}>
        {
          partners.length
            ?
            <Swiper
              slidesPerView='auto'
            >{
                partners.map((partner) => (
                  <SwiperSlide
                    key={partner.id}
                    className={styles.swiperSlide}
                  >
                    <RoundItem
                      src={partner.type === 'image' ? `${baseUrl}${partner.image}` : null}
                      videoSrc={partner.type === 'video' ? `${baseUrl}${partner.video}` : null}
                    />
                  </SwiperSlide>
                ))}
            </Swiper>
            :
            ''
        }
      </div>
    </div>
  )
}