import React, { useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import styles from './SliderSlide.module.scss'

import 'swiper/scss';
import './SliderSlide.scss'
import classNames from 'classnames';
import { ITiresCategorySlide } from "@/api/models/tires.models";
import axiosInstance from "@/api/axiosInstance";
import { useDispatch } from 'react-redux';
import { hidePullCursor, showPullCursor } from '@/store/slices/PullCursor';

export const SliderSlide = ({
  title,
  slides,
}: {
  title: string | TrustedHTML,
  slides: ITiresCategorySlide[]
}) => {
  return (
    <div className={styles.pageSection}>
      <span className={styles.titleWrapper} dangerouslySetInnerHTML={{ __html: title }}></span>
      <div className={styles.swiperWrapper}>
        <SwiperComponent slides={slides} />
      </div>
    </div>
  )
}

const SwiperComponent = ({
  slides
}: {
  slides: ITiresCategorySlide[]
}) => {
  const dispatch = useDispatch();

  const [loopSlide, setLoopSlide] = useState(-1);
  const baseUrl = axiosInstance.defaults.baseURL;

  function onSlideMouseEnter(index: number) {
    setLoopSlide(index);
  }

  function onSlideMouseLeave() {
    setLoopSlide(-1);
  }

  function isCurrentSlide(index: number): boolean {
    return index === loopSlide;
  }

  function enableCursor() {
    dispatch(showPullCursor());
  }

  function disableCursor() {
    dispatch(hidePullCursor());
  }

  return (
    <div
      className={styles.SliderSlide__swiperWrapper}
      onMouseEnter={enableCursor}
      onMouseLeave={disableCursor}
    >
      <Swiper
        spaceBetween={10}
        slidesPerView='auto'
        slidesOffsetAfter={52}
        slidesOffsetBefore={52}

        breakpoints={{
          320: {
            slidesOffsetAfter: 16,
            slidesOffsetBefore: 16
          },
          768: {
            slidesOffsetAfter: 24,
            slidesOffsetBefore: 24
          },
          1280: {
            slidesOffsetAfter: 52,
            slidesOffsetBefore: 52
          },
        }}
      >
        {slides.map((slide, index) => (
          <SwiperSlide
            key={slide.id}
            onMouseEnter={() => onSlideMouseEnter(index)}
            onMouseLeave={onSlideMouseLeave}
            className={classNames(
              'sliderSlide__slide', { 'loopSlide': isCurrentSlide(index) })}
          >
            <div
              className={classNames(
                styles.swiperItem, { 'loopSlide': isCurrentSlide(index) })}
            >
              <div
                className={classNames(
                  styles.swiperItem__videoWrapper,
                )}
              >
                {slide.video && <video
                  className={classNames(
                    styles.swiperItem__video,
                    'swiperItem__video',
                    { 'loopVideo': isCurrentSlide(index) }
                  )}
                  loop={true}
                  muted={true}
                  preload='auto'
                  autoPlay={true}
                  controls={false}
                  playsInline={true}
                  src={`${baseUrl}${slide.video}`}
                />}

                {slide.image && <img
                  className={classNames(
                    styles.swiperItem__image,
                    'swiperItem__video',
                    { 'loopVideo': isCurrentSlide(index) }
                  )}
                  src={`${baseUrl}${slide.image}`}
                  alt={slide.title}
                />}
              </div>
              <p className={classNames(
                styles.swiperItem__title,
              )}>{slide.title}</p>
              <p className={classNames(
                styles.swiperItem__text,
                'swiperItem__text',
                { 'showText': isCurrentSlide(index) }
              )}>
                {slide?.desc ?? ''}
              </p>
            </div>

          </SwiperSlide>
        ))}
      </Swiper>
    </div >
  )
};


